/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string, expireMinutes: number) {
    const date = new Date();
    const value = val;

    if (expireMinutes) {
        date.setTime(date.getTime() + expireMinutes * 60 * 1000);
    } else {
        // Set it expire in 7 days
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
    }


    // Set it
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

export function getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts !=null && parts.length === 2) {
        //@ts-ignore
        return parts.pop().split(';').shift();
    }
}

export function deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
}

export function deleteAllCookies() {
    document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
}
