export const environment = {
  production: false,
  logo: "assets/images/mn.png",
  api:"https://mouchaakapi.azurewebsites.net",
  // api: "http://localhost:3000",

  //  logo:'assets/images/vlogo.png',

  //  logo:'assets/images/roudro_logo_text.png',
  // api: "https://salezifyapi.azurewebsites.net",

  ENCRYPT_SECRET: "f0a38d2ef813a209960684ecf",
};

//get_dashboard_app_target_achievement
