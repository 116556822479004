import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../models/apiResponse";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  async primaryVsSecondary(): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/primaryVsSecondary") .toPromise();
  }


  async achievement(): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/achievement") .toPromise();
  }

  async activeFieldForce(): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/activeFieldForce") .toPromise();
  }
  async mhSales(code?:any): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/mhSales?code="+(code?code:"")) .toPromise();
  }
  async categoryWiseTrgVsSales(categoryCode?:any,brandCode?:any): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/categoryWiseTrgVsSales?CategoryCode="+(categoryCode?categoryCode:"")+"&BrandCode="+(brandCode?brandCode:"")) .toPromise();
  }

  async primaryCard(code?:any): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/primaryCard") .toPromise();
  }

  async orderCard(code?:any): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/orderCard") .toPromise();
  }
  async invoiceCard(code?:any): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/invoiceCard") .toPromise();
  }

  async visitVsOrderCard(code?:any): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/visitVsOrderCard") .toPromise();
  }

  async lpcLpmCard(code?:any): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/dashboard/lpcLpmCard") .toPromise();
  }
}
