import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "src/models/apiResponse";
import * as crypto from "crypto-js";
@Injectable({
  providedIn: "root",
})
export class BasicDataService {
  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem("access_token");
  }

  getListData(pageSize: number | any, type: string, filter: any, whereObj: any, cols: any, withCount?: boolean) {
    var data = crypto.AES.encrypt(
      JSON.stringify({
        pageSize,
        type,
        filter,
        whereObj,
        cols,
        withCount,
      }),
      environment.ENCRYPT_SECRET
    ).toString();
    return this.http.post<any>(environment.api + "/basic-data/listData", { data: data }).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getPublicDataDetails(type: string, id: string) {
    return this.http.get<any>(environment.api + "/basic-data/details/" + type + "/" + id).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  putData(data: any, type: string) {
    return this.http.post<any>(environment.api + "/" + type, data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  // getDataList(path: String) {
  //   return this.http.post<any>(environment.api + '/' + path, {})
  //     .pipe(map((res) => {
  //       return res as ApiResponse;
  //     }));
  // }

  getData(apiPath: string, id: number) {
    return this.http.post<any>(environment.api + "/" + apiPath, { Id: id }).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getDropdownData(val: any) {
    var data = crypto.AES.encrypt(JSON.stringify(val), environment.ENCRYPT_SECRET).toString();
    return this.http.post<any>(environment.api + "/basic-data/getDropDownValues", { data: data }).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getTypeAheadDropdownData(type: string, cols: string[], whereObj: any, pattern: string, filter?: string, joinStatement?: string) {
    let val: any = {};
    val.ShortName = type;
    val.cols = cols;
    val.whereObj = whereObj;
    val.pattern = pattern;
    val.filter = filter;
    val.joinStatement = joinStatement;

    var data = crypto.AES.encrypt(JSON.stringify(val), environment.ENCRYPT_SECRET).toString();

    return this.http.post<any>(environment.api + "/basic-data/getTypeAheadDropdownData", { data: data }).pipe(
      map((res) => {
        if (res.Success && pattern && pattern.length > 0) {
          return res.Data;
        } else {
          return [];
        }
      })
    );
  }

  getMarketHierarchy() {
    return this.http.get<any>(environment.api + "/basic-data/getMarketHierarchyList").pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getDepartments() {
    return this.http.get<any>(environment.api + "/basic-data/getDepartments").pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  saveMarketHierarchy(data: any) {
    return this.http.post<any>(environment.api + "/basic-data/saveOrUpdateMarketHierarchy", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getLastCode(table: string) {
    return this.http.get<any>(environment.api + "/basic-data/lastCode/" + table).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  checkCodeExist(table: string, code: string) {
    return this.http.get<any>(environment.api + "/basic-data/codeExits/" + table + "/" + code).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getLastMhCode(parentCode: string) {
    return this.http.get<any>(environment.api + "/basic-data/lastMhCode/" + parentCode).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getDataById(path: string, id: string) {
    return this.http.get<any>(environment.api + "/basic-data/" + path + "/" + id).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getProductPrices(code: string) {
    return this.http.get<any>(environment.api + "/basic-data/product/" + code + "/prices").pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getFilteredData(path: string, filter: string, whereObj: any, lastKey: number, pageSize: number) {
    var data = crypto.AES.encrypt(JSON.stringify({ whereObj, filter, lastKey, pageSize }), environment.ENCRYPT_SECRET).toString();
    return this.http.post<any>(environment.api + "/basic-data/" + path, { data: data }).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  saveOrUpdateData(data: any, path: string) {
    return this.http.post<any>(environment.api + "/basic-data/" + path, data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  syncData(path: string) {
    return this.http.get<any>(environment.api + "/basic-data/" + path).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getProductByCustomerCatAndDepartment(customerCatCode: string, departmentCode: string) {
    return this.http.get<any>(environment.api + "/basic-data/getProductByCustomerCatAndDepartment/" + customerCatCode + "/" + departmentCode).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getPrimaryProductByCustomerCatAndDepartment(customerCatCode: string, departmentCode: string) {
    return this.http.get<any>(environment.api + "/basic-data/getPrimaryProductByCustomerCatAndDepartment/" + customerCatCode + "/" + departmentCode).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  async userWiseMarketHierarchy(): Promise<ApiResponse> {
    return await this.http.get<any>(environment.api + "/basic-data/getUserWiseMarketHierarchy").toPromise();
  }

  async getParentWiseChildMH(mhCodes: string): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/basic-data/getParentWiseChildMH", { mhCodes: mhCodes }).toPromise();
  }

  async getChildFF(marketCodes: string, ffCode: string): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/basic-data/getChildFF", { marketCodes: marketCodes, ffCode: ffCode }).toPromise();
  }

  async deleteCustomer(customerCode: string): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/basic-data/deleteCustomer", { CustomerCode: customerCode }).toPromise();
  }

  getProductsForRequisition(distributorCode: string, departmentCode: string) {
    return this.http.get<any>(environment.api + "/basic-data/getProductsForRequisition/" + distributorCode + "/" + departmentCode).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  resetCustomerLocation(data: any) {
    return this.http.post<any>(environment.api + "/basic-data/resetCustomerLocation", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  migrate(type: string, data: any) {
    return this.http.post<any>(environment.api + "/basic-data/migrate/" + type, data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  customerAnalytics() {
    return this.http.post<any>(environment.api + "/basic-data/scheduledJobCustomerAnylytics",{}).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  async getDistributorStock(departmentCode: string, distributorCode: string): Promise<ApiResponse> {
    return this.http
      .get<any>(environment.api + `/basic-data/getDistributorStock/${distributorCode}/${departmentCode}`)
      .toPromise();
  }
}
