import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../models/apiResponse";

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {}

  async stockReport(pageSize: number, lastKey: Date, filter: any, whereObj: any): Promise<ApiResponse> {
    return await this.http
      .post<any>(environment.api + "/report/stock", {
        pageSize,
        lastKey,
        filter,
        whereObj,
      })
      .toPromise();
  }

  async dynamicReport(path: string, filter: any): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/report/" + path, filter).toPromise();
  }

  async activity(filter:any): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/report/activity" , filter).toPromise();
  
  }


  async userLocation(ActivityDate?: string,MHCodes?:string): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/report/getDayWiseActivity" , {ActivityDate:ActivityDate ,MHCodes:MHCodes}).toPromise();
  }
}
