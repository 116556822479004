import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/models/apiResponse';

@Injectable({
    providedIn: 'root'
  })
  export class UserService {
    constructor(private http: HttpClient) { }
  
    getToken() {
      return localStorage.getItem('access_token');
    }
  
    getFilteredList(
      filter: any,
      whereObj: any,
      lastKey:number,
      pageSize: number|any,
      pageNumber: number|any,
    ) {
      return this.http
        .post<any>(environment.api + '/user/getFilteredData', {
          pageSize:pageSize,
          pageNumber:pageNumber,
          filter:filter,
          whereObj:whereObj,
          lastKey:lastKey
        })
        .pipe(
          map((res) => {
            return res as ApiResponse;
          })
        );
    }
  
    getDetails(
      id:string
    ) {
      return this.http
        .get<any>(environment.api + '/user/details/' + id)
        .pipe(
          map((res) => {
            return res as ApiResponse;
          })
        );
    }
    checkLoginIdExist(
      id:string
    ) {
      return this.http
        .get<any>(environment.api + '/user/loginIdExist/' + id)
        .pipe(
          map((res) => {
            return res as ApiResponse;
          })
        );
    }
  
  
    saveOrUpdate(
      data:any
    ) {
      return this.http
        .post<any>(environment.api + '/user/saveOrUpdate', data)
        .pipe(
          map((res) => {
            return res as ApiResponse;
          })
        );
    }
  
  
  }
  