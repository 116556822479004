import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "src/models/apiResponse";
import * as crypto from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class ReturnService {
  constructor(private http: HttpClient) {}

  async getProductForTransferOrReturn(departmentCode: string, distributorCode: string, actionType: string): Promise<ApiResponse> {
    return this.http
      .post<any>(environment.api + "/return/getProductForTransferOrReturn", {
        DepartmentCode: departmentCode,
        DistributorCode: distributorCode,
        ActionType: actionType,
      })
      .toPromise();
  }

  async saveOrUpdateStockTransferOrReturn(data: any): Promise<ApiResponse> {
    return this.http.post<any>(environment.api + "/return/saveOrUpdateStockTransferOrReturn", data).toPromise();
  }

  initStockType() {
    return this.http.get<any>(environment.api + "/upload/initStockType").pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }
  primaryUploadType() {
    return this.http.get<any>(environment.api + "/upload/primaryUploadType").pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  async uploadData(type: string, data: any): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/upload/uploadData", { type: type, data: data }).toPromise();
  }

  async saveOrUpdateInitialStockByFileUpload(data: any): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/upload/saveOrUpdateInitialStockByFileUpload", { data }).toPromise();
  }
  async saveOrUpdatePrimaryUpload(data: any): Promise<ApiResponse> {
    return await this.http.post<any>(environment.api + "/upload/saveOrUpdatePrimaryUpload", { data }).toPromise();
  }
}
