import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "src/models/apiResponse";
import * as crypto from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  constructor(private http: HttpClient) {}

  getFilteredList(filter: any, whereObj: any, lastKey: number, pageSize: number | any, pageNumber: number | any) {
    var data = crypto.AES.encrypt(
      JSON.stringify({
        pageSize: pageSize,
        pageNumber: pageNumber,
        filter: filter,
        whereObj: whereObj,
        lastKey: lastKey,
      }),
      environment.ENCRYPT_SECRET
    ).toString();
    return this.http.post<any>(environment.api + "/invoice/getFilteredData", { data: data }).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }
}
