import { AfterViewInit, Component, OnInit } from "@angular/core";
import * as $ from "jquery";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "salezify-web";

  ngOnInit(): void {
    if (localStorage.getItem('prefTheme')) {
      let theme = localStorage.getItem('prefTheme');
      if (theme === 'light') {
        let bodypd = document.getElementById('body-pd')!;
        bodypd.classList.remove('dark-mode');
      } else {
        let bodypd = document.getElementById('body-pd')!;
        bodypd.classList.add('dark-mode');
      }
    } else {
      localStorage.setItem('prefTheme', 'light');
    }
  }
  ngAfterViewInit(): void {
    // import('../assets/js/feather-icons/feather.min.js');
    // import('../assets/vendors/perfect-scrollbar/perfect-scrollbar.min.js');
    // import('../assets/js/app.js');
    // import('../assets/vendors/chartjs/Chart.min.js');
    // import('../assets/vendors/apexcharts/apexcharts.min.js');
    // import('../assets/js/pages/dashboard.js');
    // import('../assets/js/main.js');
  }
}

// "src/assets/js/feather-icons/feather.min.js",
// "src/assets/vendors/perfect-scrollbar/perfect-scrollbar.min.js",
// "src/assets/vendors/chartjs/Chart.min.js",
// "src/assets/vendors/apexcharts/apexcharts.min.js",
// "src/assets/js/pages/dashboard.js",
// "src/assets/js/main.js",
// "src/assets/js/app.js"
