import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UserAuth } from "src/auth-guards/user-auth";

const routes: Routes = [
  { path: "", loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule), canActivate: [UserAuth] },
  // { path: 'samples', loadChildren: () => import('./sample/sample.module').then(m => m.SampleModule) },
  { path: "auth", loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
