import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { ToastrModule, ToastrService } from "ngx-toastr";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { UserAuth } from "src/auth-guards/user-auth";
import { AuthService } from "../services/auth.service";
import { StorageService } from "src/services/storage.service";
import { JwtInterceptor, JwtModule } from "@auth0/angular-jwt";
import { getCookie } from "src/helpers/cookie-utils";
import { ErrorInterceptor } from "src/helpers/error.interceptor";
import { BasicDataService } from "../services/basicDataService";
import { Router } from "@angular/router";
import { OrderService } from "src/services/order.service";
import { InvoiceService } from "src/services/invoice.service";
import { RequisitionService } from "../services/requisition.service";
import { UserService } from "src/services/user.service";
import { ReportService } from "src/services/report.service";
import { AgmCoreModule } from "@agm/core";
import { DashboardService } from "src/services/dashboard.service";
import { ReturnService } from "src/services/return.service";

export function tokenGetter(): any {
  return localStorage.getItem("access_token");
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDXjx5_WhY3j1WeA6y_u90K-g02bLtgW3E",
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          
          if(getCookie("access_token")){
            return localStorage.getItem("access_token");

          }else{
            return null;
          }
        },
        allowedDomains: [
          "http://localhost:4200",
          "http://localhost:5000",
          "localhost:4200",
          "localhost:5000",
          "http://localhost:3000",
          "http://192.168.0.103:3000",
          "192.168.0.103:3000",
          "http://localhost:57686",
          "localhost:57686",
          "localhost:3000",
          "https://salezify-demo.web.app",
          "salezify-demo.web.app",
          "https://mnetwork-sfa.web.app",
          "mnetwork-sfa.web.app",
          "https://salezifyapi.azurewebsites.net",
          "salezifyapi.azurewebsites.net",
          "https://mnetwork.azurewebsites.net",
          "mnetwork.azurewebsites.net",
          "https://mouchaakapi.azurewebsites.net",
          "mouchaakapi.azurewebsites.net",
        ],
      },
    }),
  ],
  providers: [
    AuthService,
    OrderService,
    InvoiceService,
    RequisitionService,
    UserService,
    UserAuth,
    StorageService,
    DashboardService,
    BasicDataService,
    ReportService,
    ReturnService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (toast: ToastrService, spinner: NgxSpinnerService, router: Router) {
        return new ErrorInterceptor(toast, spinner, router);
      },
      multi: true,
      deps: [ToastrService, NgxSpinnerService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
