import { Injectable } from "@angular/core";
import { setCookie } from "../helpers/cookie-utils";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import * as crypto from "crypto-js";
import { StorageService } from "./storage.service";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../models/apiResponse";

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  login(username: string, password: string,remember:boolean) {
    let cred = { username: username, password: password , device :"WEB"};
    var encryptedCred = crypto.AES.encrypt(JSON.stringify(cred), environment.ENCRYPT_SECRET).toString();

    return this.http
      .post<any>(environment.api + "/auth/login", {
        args: encryptedCred,
      })
      .pipe(
        map((res) => {
         try {
          if (res.Success) {
            let user: any = res.Data.user;
            user.WebRoles = "|"+user.WebRoles+"|";
            let token = res.Data.accessToken;
            setCookie("current_session", "slf_" + Date.now().toString(), remember ? 1440 : 59);
            this.storage.storeData("_c_u", user);
            localStorage.setItem("access_token", token);
            setCookie("access_token", '1', remember ? 1440 : 59);
          }
         } catch (error) {
          console.error(error);
         }
          return res as ApiResponse;
        })
      );
  }

}
