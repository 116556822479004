import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "src/models/apiResponse";
import * as crypto from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class RequisitionService {
  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem("access_token");
  }

  getFilteredList(filter: any, whereObj: any, lastKey: number, pageSize: number | any, pageNumber: number | any) {
    var data = crypto.AES.encrypt(
      JSON.stringify({
        pageSize: pageSize,
        pageNumber: pageNumber,
        filter: filter,
        whereObj: whereObj,
        lastKey: lastKey,
      }),
      environment.ENCRYPT_SECRET
    ).toString();
    return this.http.post<any>(environment.api + "/requisition/getFilteredData", { data: data }).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }
  getIssuedRequisitionListData(filter: any, whereObj: any, lastKey: number, pageSize: number | any, pageNumber: number | any) {
    return this.http
      .post<any>(environment.api + "/requisition/getIssuedRequisitionListData", {
        pageSize: pageSize,
        pageNumber: pageNumber,
        filter: filter,
        whereObj: whereObj,
        lastKey: lastKey,
      })
      .pipe(
        map((res) => {
          return res as ApiResponse;
        })
      );
  }

  getIssuedRequisitionTypeAheadData(filter: any, whereObj: any, pattern: string) {
    filter +=
      "AND ( InventoryTran.ReferenceTranNo LIKE '%" +
      pattern +
      "%' OR InventoryTran.TranNo LIKE '%" +
      pattern +
      "%' OR InventoryTran.ToDistributorName LIKE '%" +
      pattern +
      "%' OR InventoryTran.ToDistributorCode LIKE '%" +
      pattern +
      "%'   ) ";

    return this.http
      .post<any>(environment.api + "/requisition/getIssuedRequisitionListData", {
        pageSize: 10,
        pageNumber: 0,
        filter: filter,
        whereObj: whereObj,
        lastKey: 0,
      })
      .pipe(
        map((res) => {
          if (res.Success && pattern && pattern.length > 0) {
            return res.Data;
          } else {
            return [];
          }
        })
      );
  }

  getDetails(requisitionNo: string) {
    return this.http.get<any>(environment.api + "/requisition/details/" + requisitionNo).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getIssueDetails(inventoryTranNo: string) {
    return this.http.get<any>(environment.api + "/requisition/issuedDetails/" + inventoryTranNo).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  saveOrUpdate(data: any) {
    return this.http.post<any>(environment.api + "/requisition/saveOrUpdate", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  getDiscounts(department: string, startDate: string, endDate: string): Promise<ApiResponse> {
    return this.http.post<any>(environment.api + "/requisition/getDiscounts", { department: department, startDate: startDate, endDate: endDate }).toPromise();
  }

  approve(data: any) {
    return this.http.post<any>(environment.api + "/requisition/approve", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }
  issue(data: any) {
    return this.http.post<any>(environment.api + "/requisition/issue", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  receive(data: any) {
    return this.http.post<any>(environment.api + "/requisition/receive", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }

  removeRequisitionBeforeIssue(data: any) {
    return this.http.post<any>(environment.api + "/requisition/removeRequisitionBeforeIssue", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }
  
  removeRequisitionBeforeReceive(data: any) {
    return this.http.post<any>(environment.api + "/requisition/removeRequisitionBeforeReceive", data).pipe(
      map((res) => {
        return res as ApiResponse;
      })
    );
  }
}
